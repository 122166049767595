import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { Text, Title, SquaredButton, Input, Checkbox, Link } from 'elements';
import { FormikEmailInput } from 'components';
import { TextWrapper } from './AccountForm.styled';

import { toPhoneNumber } from 'utils/pattern-utils';
import { hasErrorOnTouchedFields } from 'utils/form-utils';
import { setUserValue } from 'app/reduxState/user';
import paths from 'constants/paths';

const emailInUseError = (
  <>
    Sorry, an account with the entered email address already exists.{' '}
    <Link href={paths.LOGIN} modifiers={['smallBig', 'underline']}>
      Login now.
    </Link>
  </>
);

const InnerForm = ({ title, subtitle, createCTA, termsCopy, loginCTACopy, isFetching, setIsFetching }) => {
  const dispatch = useDispatch();
  const { setFieldValue, submitForm, values, errors, touched } = useFormikContext();
  const isSubmitDisabled = !values.termsCheck || hasErrorOnTouchedFields({ errors, touched });

  useEffect(() => {
    if (!!setIsFetching && hasErrorOnTouchedFields({ errors, touched })) setIsFetching(false);
  }, [errors, touched, setIsFetching]);

  const onEmailBlur = (_, { email }) => {
    dispatch(setUserValue({ label: 'email', value: email }));
  };

  const onPhoneChange = (event) => {
    setFieldValue('phone', toPhoneNumber(event.target.value));
  };

  const onStartSubmission = () => {
    setIsFetching(true);
    submitForm();
  };

  return (
    <Form noValidate>
      <TextWrapper>
        <Title as='h2' content={title} modifiers={['secondarySmall', 'center']} />
        <Text content={subtitle} modifiers={['center']} />
      </TextWrapper>
      <Field
        required
        variant='filled'
        name='name'
        type='text'
        label='First Name'
        autoComplete='name'
        helperText='*Required'
        component={Input}
      />
      <Field
        required
        variant='filled'
        name='lastName'
        type='text'
        label='Last Name'
        autoComplete='lastname'
        helperText='*Required'
        component={Input}
      />
      <FormikEmailInput
        required
        variant='filled'
        name='email'
        label='Email'
        helperText='*Required'
        autoComplete='email'
        onBlur={onEmailBlur}
        emailInUseError={emailInUseError}
      />
      <Field required variant='filled' name='password' type='password' label='Password' helperText='*Required' component={Input} />
      <Field
        variant='filled'
        name='phone'
        label='Phone Number'
        component={Input}
        autoComplete='tel'
        inputMode='numeric'
        onChange={onPhoneChange}
      />
      <Field
        name='termsCheck'
        component={Checkbox}
        modifiers='leftAlign'
        label={<Text content={termsCopy} modifiers={['light', 'brandFont']} isHTML />}
      />
      <SquaredButton
        type='submit'
        label={isFetching ? 'SENDING...' : createCTA.linkText}
        disabled={isSubmitDisabled || isFetching}
        modifiers={[isSubmitDisabled && 'disabled', isFetching && 'loading']}
        onClick={onStartSubmission}
      />
      <Text content={loginCTACopy} modifiers={['light', 'brandFont', 'center']} isHTML />
    </Form>
  );
};

InnerForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  termsCopy: PropTypes.string,
  loginCTACopy: PropTypes.string,
  createCTA: PropTypes.object,
  isFetching: PropTypes.bool,
  setIsFetching: PropTypes.func,
};

export default InnerForm;
