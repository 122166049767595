import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { useLocation } from '@reach/router';

import { FormWrapper, FormGroup } from './AccountForm.styled';

import { getCreateAccountPayload, getValidationSchema, initialFormValues } from './FormHelpers';
import { useAuth, useStopLoading } from 'utils/hooks';
import { getContentMessages } from 'app/reduxState/contentful';
import { userCreateAccount, getLoginState } from 'app/reduxState/user';
import paths from 'constants/paths';
import InnerForm from './InnerForm';

const AccountForm = ({ title, subtitle, termsCopy, loginCTACopy, createCTA }) => {
  const [isFetching, setIsFetching] = useState(false);
  const isLoggedIn = useSelector(getLoginState);
  const dispatch = useDispatch();
  const auth = useAuth();
  const messages = useSelector(getContentMessages);
  const searchLocation = useLocation().search;
  const { redirect } = queryString.parse(searchLocation);

  useStopLoading(!!Object.values(messages).length);

  useEffect(() => {
    if (isLoggedIn) redirect ? navigate(redirect) : navigate(paths.MY_FARM);
  }, [isLoggedIn, redirect]);

  const handleSubmit = (values) => {
    setIsFetching(true);
    const payload = getCreateAccountPayload(values);
    dispatch(userCreateAccount({ auth, email: values.email, password: values.password, accountData: payload, setIsFetching }));
  };

  return (
    <FormWrapper>
      <FormGroup>
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={getValidationSchema(messages)}
        >
          <InnerForm
            title={title}
            subtitle={subtitle}
            termsCopy={termsCopy}
            loginCTACopy={loginCTACopy}
            createCTA={createCTA}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
          />
        </Formik>
      </FormGroup>
    </FormWrapper>
  );
};

AccountForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  termsCopy: PropTypes.string,
  loginCTACopy: PropTypes.string,
  createCTA: PropTypes.object,
};

export default AccountForm;
